import React, { useState } from "react";
import {
  RiHome7Line,
  RiUser3Line,
  RiMacbookLine,
  RiChatPollLine,
} from "react-icons/ri";
import "./nav.scss";

const Nav: React.FC = () => {
  const [activeNav, setActiveNav] = useState<string>("#");

  const addClassActive = (currentUrl: string) => {
    return activeNav === currentUrl ? "active" : "";
  };

  return (
    <nav>
      <a
        href="#home"
        onClick={() => setActiveNav("#home")}
        className={addClassActive("#home")}
      >
        <RiHome7Line />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={addClassActive("#about")}
      >
        <RiUser3Line />
      </a>
      <a
        href="#experience"
        onClick={() => setActiveNav("#experience")}
        className={addClassActive("#experience")}
      >
        <RiMacbookLine />
      </a>
      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={addClassActive("#contact")}
      >
        <RiChatPollLine />
      </a>
    </nav>
  );
};

export default Nav;
