import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { AiOutlineMail, AiFillLinkedin } from "react-icons/ai";
import "./contact.scss";
import toast, { Toaster } from "react-hot-toast";

const notify = () =>
  toast("message Successfully send", {
    icon: "👏",
  });

const Contact: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formRef.current) {
      emailjs.sendForm(
        "service_27ta8cc",
        "template_osg53aa",
        formRef.current,
        "g0V-mO2yT9w0Ho9x1"
      );

      (e.target as HTMLFormElement).reset();

      notify();
    }
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <AiOutlineMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>miladr0r@gmail.com</h5>
            <a href="mailto:miladr0r@gmail.com">Send a message</a>
          </article>
          <article className="contact__option">
            <AiFillLinkedin className="contact__option-icon" />
            <h4>Linkedin</h4>
            <h5>milad-ranjbar</h5>
            <a
              href="https://www.linkedin.com/in/milad-ranjbar/"
              target="_blank"
              rel="noreferrer"
            >
              view
            </a>
          </article>
        </div>
        {/* end of contact options */}

        <form ref={formRef} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows={7}
            placeholder="Your Message"
            required
          />
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
          <Toaster />
        </form>
      </div>
    </section>
  );
};

export default Contact;
