import React from "react";
import { resumeLink, telegramProfile } from "../../data/links";

const CTA = () => {
  return (
    <div className="cta">
      <a href={resumeLink} className="btn" target="_blank" rel="noreferrer">
        Download CV
      </a>
      <a
        href={telegramProfile}
        target="_blank"
        rel="noreferrer"
        className="btn btn-primary"
      >
        Let's Talk
      </a>
    </div>
  );
};

export default CTA;
