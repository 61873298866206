import React from "react";
import IMG1 from "../../assets/portfolio1.png";
import IMG2 from "../../assets/portfolio2.png";
import IMG3 from "../../assets/portfolio3.png";

import { portfolios } from "../../data/portfolios";
import sortBy from "../../utils/sortBy";
import "./portfolio.scss";

const Portfolio: React.FC = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {portfolios
          .sort((a, b) => sortBy(a, b, "id"))
          .map((portfolio, i) => (
            <article key={i} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={portfolio.img} alt={portfolio.title} />
              </div>

              <h4>{portfolio.title}</h4>
              <div className="portfolio__item-cta">
                <a
                  href={portfolio.source.url}
                  className="btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  {portfolio.source.title}
                </a>
                <a
                  href={portfolio.liveDemo.url}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  {portfolio.liveDemo.title}
                </a>
              </div>
            </article>
          ))}
      </div>
    </section>
  );
};

export default Portfolio;
