import React from "react";
import "./about.scss";
import MeImage from "../../assets/m4.webp";
import { SiSololearn } from "react-icons/si";
import { HiUsers, HiArchive } from "react-icons/hi";

const About: React.FC = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={MeImage} alt="Milad Ranjbar" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <SiSololearn className="about__icon" />
              <h5>Experience</h5>
              <small>5+ Years Working</small>
            </article>
            <article className="about__card">
              <HiUsers className="about__icon" />
              <h5>companies</h5>
              <small>3+ Worldwide</small>
            </article>
            <article className="about__card">
              <HiArchive className="about__icon" />
              <h5>Projects</h5>
              <small>10+ completed</small>
            </article>
          </div>

          <p>
            My name is Milad. I'm a Senior Software Engineer currently at
            Adakerja with over 5 years of building e-commerce websites and apps
            in fast-paced collaborative environments. using Javascript, Node.js,
            React, Postgres, MongoDB, Redis, Graphql, and AWS services to build
            well-functioning APIs and scalable architecture using microservice.
          </p>
          <div className="btn-container">
            <a href="#contact" className="btn btn-primary">
              Let's Talk
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
