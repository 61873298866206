import IPortfolio from "../types/IPortfolio";
import IMG1 from "../assets/portfolio1.png";
import IMG2 from "../assets/portfolio2.png";
import IMG3 from "../assets/portfolio3.png";

export const portfolios: IPortfolio[] = [
  {
    id: 1,
    title: "Discord Clone",
    img: IMG1,
    source: {
      title: "Github",
      url: "https://github.com/miladr0/discord-clone",
    },
    liveDemo: {
      title: "Live Demo",
      url: "https://discord-clone-ashy.vercel.app/",
    },
  },
  {
    id: 2,
    title: "React Admin panel with multi Theme",
    img: IMG2,

    source: {
      title: "Github",
      url: "https://github.com/miladr0/simple-admin-panel",
    },
    liveDemo: {
      title: "Live Demo",
      url: "http://simple-admin-panel.vercel.app/",
    },
  },
  {
    id: 3,
    title: "task-do with react and tailwindcss",
    img: IMG3,

    source: {
      title: "Github",
      url: "https://github.com/miladr0/task-do",
    },
    liveDemo: {
      title: "Live Demo",
      url: "https://codesandbox.io/s/nifty-night-s1wv3",
    },
  },
];
